import React, { useState, useEffect }  from 'react';
//import ReactDOMServer       from 'react-dom/server';
import { Logger }           from 'aws-amplify';
import { withStyles }       from '@material-ui/core/styles';
import { useTheme }         from '@material-ui/core/styles';
import useMediaQuery        from '@material-ui/core/useMediaQuery';

import Button               from '@material-ui/core/Button';
import Dialog               from '@material-ui/core/Dialog';
import DialogActions        from '@material-ui/core/DialogActions';
import DialogContent        from '@material-ui/core/DialogContent';
import DialogContentText    from '@material-ui/core/DialogContentText';
import DialogTitle          from '@material-ui/core/DialogTitle';
import TextField            from '@material-ui/core/TextField';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
/*
import Typography           from '@material-ui/core/Typography';
import Accordion            from '@material-ui/core/Accordion';
import MuiAccordionSummary  from '@material-ui/core/AccordionSummary';
import AccordionDetails     from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon       from '@material-ui/icons/ExpandMore';
//import Tooltip              from '@material-ui/core/Tooltip';


import MultiSelect          from './FormElements/MultiSelect';
import Select               from './FormElements/Select';
import BooleanElement       from './FormElements/Checkbox';
import QueryBuilder         from './FormElements/QueryBuilder';

import Icon                 from 'components/Icon';
*/
//import OperatorService from "../services/OperatorService";
//import { getIcon } from "../data/Operators";

const logger = new Logger('UploadDialog');



export default function FormDialog(props) {
    logger.debug("props", props);
    
    /**
     * CSVToArray parses any String of Data including '\r' '\n' characters,
     * and returns an array with the rows of data.
     * @param {String} CSV_string - the CSV string you need to parse
     * @param {String} delimiter - the delimeter used to separate fields of data
     * @returns {Array} rows - rows of CSV where first row are column headers
     */
    const csvToArrayOfObjects = (CSV_string, delimiter) => {
       delimiter = (delimiter || ","); // user-supplied delimeter or default comma
    
       var pattern = new RegExp( // regular expression to parse the CSV values.
         ( // Delimiters:
           "(\\" + delimiter + "|\\r?\\n|\\r|^)" +
           // Quoted fields.
           "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
           // Standard fields.
           "([^\"\\" + delimiter + "\\r\\n]*))"
         ), "gi"
       );
    
       var rows = [[]];  // array to hold our data. First row is column headers.
       // array to hold our individual pattern matching groups:
       var matches = false; // false if we don't find any matches
       // Loop until we no longer find a regular expression match
       while (matches = pattern.exec( CSV_string )) {
           var matched_delimiter = matches[1]; // Get the matched delimiter
           // Check if the delimiter has a length (and is not the start of string)
           // and if it matches field delimiter. If not, it is a row delimiter.
           if (matched_delimiter.length && matched_delimiter !== delimiter) {
             // Since this is a new row of data, add an empty row to the array.
             rows.push( [] );
           }
           var matched_value;
           // Once we have eliminated the delimiter, check to see
           // what kind of value was captured (quoted or unquoted):
           if (matches[2]) { // found quoted value. unescape any double quotes.
            matched_value = matches[2].replace(
              new RegExp( "\"\"", "g" ), "\""
            );
           } else { // found a non-quoted value
             matched_value = matches[3];
           }
           // Now that we have our value string, let's add
           // it to the data array.
           rows[rows.length - 1].push(matched_value);
       }
       
      logger.debug("csvToArrayOfObjects", rows);
      return rows;
    };
    
    /**
     * Look up the header ids from the titles
     * Then turn each row into an key/value object
     */
    const parseContents = (contents) => {
        
        //logger.debug("parseContents contents", contents);
        var json = [];
        try {
            json = JSON.parse(contents);
        }
        catch (e) {
            var new_data;
            //logger.debug("parseContents not JSON ");
            // get the data from csv to json
            new_data = csvToArrayOfObjects(contents);
        
            // convert csv json to kvp json
            var header = new_data[0];
            if (header) {
                // convert the header names to column keys.
                var header_fields = [];
                header.forEach((header_text) => {
                    var group_header_name = header_text.substring(0, header_text.indexOf(':')).trim();
                    var header_name =  header_text = header_text.substring(header_text.indexOf(':')+1).trim();
                    
                    var group = props.columnDefs.filter(obj => {return (obj['headerName'] === group_header_name) })[0];
                    if (group && group.children) {
                        var child = group.children.filter(obj => {return (obj['headerName'] === header_name) })[0];
                        if (child) {
                            header_fields.push(child.field);
                        }
                        
                    }
                });
                //logger.debug("parseContents headerfields", header_fields);        
                
                // remap the values to the keys
                for (var i = 1; i < new_data.length; i++) {
                    var new_row = {};
                    header_fields.forEach((field, index) => {
                        //logger.debug("parseContents3", field, index, new_data[i][index]);
                        new_row[field] = new_data[i][index];    
                    });
                    json.push(new_row);
                }
            }
        }
        logger.debug("parseContents json1", json);
        
        // validate the data and set errors
        json.forEach((row, index) => {
            //logger.debug("parseContents json row", row, index);  
            var error = [];
            props.columnDefs.forEach((header) => {
                if (header.children) {
                    header.children.forEach((column) => {
                        if (column.required && !row[column.field]) {
                            error.push("Row " + index + ": Missing required value: " + column.field);
                        }
                    });
                }
            });
            json[index]['upload_id'] = "upload_id" + index;
            json[index]['error'] = error;
        });
        logger.debug("parseContents json2", json);
        return json;
    };


    const [selectedFile, setSelectedFile] = useState({});
    const [content, setContent] = useState(null);
    const [tabValue, setTabValue] = React.useState(0);
    const [checkboxlist, setCheckboxlist] = React.useState({});
    const [uploadState, setUploadState] = React.useState();
    

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    var handleClose = () => {
        setSelectedFile({});
        setContent(null);
        setTabValue(0);
        setUploadState();
        props.handleClose();
    };
    
    const handleUploadSelected = () => {
        logger.debug("handleUploadSelected checkboxlist", checkboxlist, content);
        setUploadState("uploading");
        
        
        var records = [];
        var results = {};
        for (const c in checkboxlist) {
            logger.debug("handleUploadSelected checkboxlist", c);
            if (checkboxlist[c]) {
                
                var record = content.find(element => element['upload_id'] === c);
                const copiedRecord = Object.assign({}, record); // have to copy so the content state doesn't change.
    
                if (copiedRecord) {
                    logger.debug("handleUploadSelected record", record);
                    delete copiedRecord.error;
                    delete copiedRecord.upload_id;
                    
                    records.push(copiedRecord);
                }
                else {
                    // this should never happen
                    logger.debug("handleUploadSelected record not found", c);
                }
            }
            
        }
        if (records.length) {
            props.upload(records).then((response) => {
                logger.debug("handleUploadSelected result", response);
                results = response;
                setUploadState(results);
            });
            
        }
        else {
            // should never hit this code, but if it does...
            results = {success: false, error: "No records found"};
            setUploadState(results);
        }
    };

        
    
    
    const handleCheckboxChange = (event) => {
        logger.debug("handleCheckboxChange", event);
        setCheckboxlist({
            ...checkboxlist,
            [event.target.name]: event.target.checked,
        });
    };
    
    const handleFileSelect = (event) => {
        const max_fileSize = 10;
        logger.debug('handleFileUpload', event);
        logger.debug('handleFileUpload', event.target);
        setSelectedFile(event.target.files[0]);
        const fileSizeInMB = (event.target.files[0].size / (1024 * 1024)).toFixed(2);
        
       
        if (fileSizeInMB <= max_fileSize) {
            logger.debug('handleFileUpload filesize ok', event.target.files[0].size, fileSizeInMB);
            var reader = new FileReader();
            reader.onload = function(e) {
                var contents = e.target.result;
               
        
                setContent(parseContents(contents));
            };
            reader.readAsText(event.target.files[0]);
        }
        else {
            logger.debug('handleFileUpload filesize bad', event.target.files[0].size, fileSizeInMB);
            setContent([{error: "File is too large at " + fileSizeInMB + " MB.  File must be less than " + max_fileSize + " MB."}]);
        }
        logger.debug('handleFileUpload', event.target.files);
        logger.debug('handleFileUpload', event.target.files[0]);
    };
    
    useEffect(() => {
        var checked = {};
        if (content) {
            content.forEach((row) => {
                if (!row['error'].length) {
                    checked[row['upload_id']] = true;  
                }
            });
        }
        logger.debug("useEffect content", checked);
        
        setCheckboxlist(checked);
       
    }, [content]);
    
    var rulelist = [];
    var errorlist = [];
    
    var error_label = "Errors";
    if (errorlist.length) error_label += " (" + errorlist.length + ")";

    var upload_allowed = false;
    var select_file_disabled = false;
    var checkboxes_disabled = false;
    var message;
    if (uploadState === "uploading") {
        select_file_disabled = true;
        checkboxes_disabled = true;
        message = "Uploading records.";
        
    }
    else if (typeof uploadState === 'object') {
        logger.debug("UploadState is object", uploadState);
        if (uploadState.error) {
            message = uploadState.error;
        }
        else if (uploadState.message)
        {
            message = uploadState.message;
        }
    }
    else {
        logger.debug("checkboxlist list", checkboxlist);
        for (const c in checkboxlist) {
            logger.debug("checkboxlist item", c);
            if (checkboxlist[c]) upload_allowed = true;
        }
    }

    if (content) {
        content.forEach((row) => {
           if (row['error'].length) {
               errorlist.push(<p style={{padding: 0, margin: 0}}>{row['error']}</p>);
           }
           else {
               logger.debug("content create checkbox", row);
               rulelist.push(<FormControlLabel control={
                    <Checkbox 
                        checked={checkboxlist[row['upload_id']]?checkboxlist[row['upload_id']]:false} 
                        onChange={handleCheckboxChange}/>} 
                        label={props.getRowTitle(row)}
                        name={row['upload_id']}
                        disabled={checkboxes_disabled}
                    />);
           }
        });
    }
    logger.debug("content", content);

    return (
        <Dialog open={props.open} fullWidth={true} maxWidth="md" onClose={handleClose} >
            <DialogTitle>Upload Data</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    
                    <Button
                        variant="contained"
                        component="label"
                        disabled={select_file_disabled}
                    >
                        Select File
                        <input
                            type="file"
                            hidden
                            accept=".txt,.json,.sql,.csv"
                            onChange={handleFileSelect}
                        />
                    </Button>
                    <span style={{paddingLeft: "16px"}}>{selectedFile.name}</span>
                </DialogContentText>
                
                <DialogContentText>
                    <Tabs value={tabValue} onChange={handleTabChange}>
                        <Tab label="Data"  disabled={content === null} />
                        <Tab label={error_label}  disabled={content === null} />
                    </Tabs>
                    <div  hidden={tabValue !== 0} style={{overflow: "auto", border: "solid 1px black", height: "200px", padding: "8px"}}>
                        <FormGroup style={{paddingLeft: "8px"}}>
                            {rulelist}
                        </FormGroup>
                    </div>
                    <div  hidden={tabValue !== 1} style={{overflow: "auto", border: "solid 1px black", height: "200px", padding: "8px"}}>
                        {errorlist}
                    </div>
                    
                    </DialogContentText>
            </DialogContent>
            <DialogActions style={{paddingBottom: "16px"}}>
                <div style={{paddingLeft: "24px", margin: 0}}>{message}</div>
                <div style={{flex: '1 0 0'}} />
                <Button variant="contained" disabled={!upload_allowed} onClick={handleUploadSelected}>Upload</Button>
                <Button variant="contained" onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}
