import BaseService from './BaseService';
import { Logger } from 'aws-amplify';
import BooleanCellRenderer from '../components/AGGrid/BooleanCellRenderer';
import CronRuleRenderer from "../components/AGGrid/CronRuleRenderer";

const logger = new Logger('UserService');

const checkboxes = [
    "enabled"
];

 var cron_options = [
    {name: "Hourly", value: "0 * * * *", default: true},
    {name: "Daily", value: "0 23 * * *"},
    {name: "Weekly", value: "0 23 * * 0"},
    {name: "Monthly", value: "0 23 1 * *"}
];

const eql = (arr1, arr2) => {
  if(arr1.length !== arr2.length){return false}
  return arr1.every((e, i) => e === arr2[i]);
}

export default class UserService extends BaseService {

    constructor() {
        let apiName = 'OriginsAPI';
        let collectionPath = '/user';
        super(apiName, collectionPath);
    }
    
    formatRowData(response) {
        var data = response.Items;
        logger.debug("formatRowData", data);
        
        data.forEach((element) => {
            if (!element['last_updated_date']) element['last_updated_date'] = element['created_date'];
            if (element['created_date']) element['created_date'] = element['created_date'].substring(0, 19).replace('T', ' ');
            if (element['last_updated_date']) element['last_updated_date'] = element['last_updated_date'].substring(0, 19).replace('T', ' ');

            checkboxes.forEach((checkbox)=> {
                if (element[checkbox] === undefined) element[checkbox] = false;
            });
        });
        return data;
    }

    getMetaData(data) {
        return {
            pagination: false,
            total_records: data.length
        };
    }
    
    /**
     * If this function doesn't exist, the assumptino is it is true
     * The function is never actually called when inserting a record.
     */
    allowed_insert() {
        return false;
    }
    
    async save(data, metaData) {
        logger.debug("save - data: ", data);
        logger.debug('save - metaData: ',metaData)
        const newData = {
            ...(data?.acl_role && {acl_role: data.acl_role} )
        }  
        
        try {
            let records = await this.fetch(data.email);
            logger.debug('save - records: ', records)
            try {
                
                if(!eql(records?.acl_role, newData?.acl_role)) {
                    const results = await this.update(data.email, newData);
                    logger.debug("save new_data", newData);
                    return { success: true, updated: true, results};    
                } else {
                    throw { name : "NoUpdates", message : "No Updates were made." }
                }
            }
            catch (error) {
                logger.debug("save service err", error);
                return { success: false, updated: false, error };
            }
        } catch (error) {
            logger.debug("save service fetch records failed", data.email);
            return { success: false, updated: false, error: error };
        }
    }
    
    getColumnDefs() {
        const test = UserService.getColDefs()
        logger.debug('User Service - getColumnDefs: ', test)
        return UserService.getColDefs();
    }
    
    /**
     * This needs to be static to have the other static functions access it.
     */
    static getColDefs() {
        var column_width = 100;

        return [
            {
            headerName: 'Details',
            defaultExpanded: true,
            children: [
                {
                    field: "pac_first_name",
                    headerName: "First Name",
                    primaryKey: true, // makes the field read only when updating but read write when inserting
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "readonly",
                    required: true,
                    newline: true
                },
                {
                    field: "pac_last_name",
                    headerName: "Last Name",
                    primaryKey: true, // makes the field read only when updating but read write when inserting
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "readonly",
                    required: true,
                    newline: true
                },
                {
                    field: "email",
                    headerName: "email",
                    primaryKey: true, // makes the field read only when updating but read write when inserting
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "readonly",
                    required: true,
                    newline: true
                },
                {
                    field: "acl_role",
                    headerName: "acl_role",
                    filter: 'agTextColumnFilter',
                    width: column_width*2,
                    resizable: true,
                    required: false,
                    formType: "multiselect",
                    options: [
                        "pac-employee",
                        "commercial",
                        "operations",
                        "operations|p",
                        "development",
                        "dpg"
                    ],
                    getValue: (data) => { 
                        return data.acl_role;
                    }
                },
                {
                    field: "pac_icao_code",
                    headerName: "pac_icao_code",
                    primaryKey: true, // makes the field read only when updating but read write when inserting
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "text",
                    required: true,
                    newline: true
                },
                {
                    field: "pac_tenant_name",
                    headerName: "pac_tenant_name",
                    primaryKey: true, // makes the field read only when updating but read write when inserting
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "text",
                    required: true,
                    newline: true
                },
            ]
        },
        {
                    headerName: 'History',
                    openByDefault: false, // column: header expanded
                    defaultExpanded: false,
                    children: [
                        {
                            field: "created_date",
                            headerName: "Creation Date",
                            filter: 'agTextColumnFilter',
                            width: column_width * 2,
                            resizable: true,
                            formType: "readonly",
                            columnGroupShow: "open"
                        }
                    ]
                },
        ];
    }
}
