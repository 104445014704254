/**
 * Operational view for viewing and editing the reports system and menu
 */

import React, {useState, useContext} from 'react';
import { Logger } from 'aws-amplify';
import AppContext               from 'pac-responsive-ui-framework/core/AppContext';
import View from 'pac-responsive-ui-framework/core/View';
import FlightFormFilter from '../components/FormFilters/Flight';
import Typography from '@material-ui/core/Typography';

import AGGrid from '../components/AGGrid';

import Service from '../services/FieldSupportService';


const logger = new Logger('OpsFieldSupportDetails');

export default function FieldSupportDetails(props) {
    logger.debug(props);
    const [footer, setFooter] = React.useState(null);
    const [key, setKey] = React.useState(0);
    var app_context = useContext(AppContext);

    const service = new Service();
    //service.setPermissions(app_context.state.user.session.acl_role);

    /*
    if (props.recordID !== "origins" && props.recordID !== "dartce") {
        logger.debug("invalid Record ID", props.recordID);
        return (<Home/>);
    }
    */

 let default_state = props.query ?? {};
    let search = window.location.search;
    let params = new URLSearchParams(search);

    if (params.get("query")) {
        try {
            default_state = JSON.parse(params.get("query"));
        } catch (e) {
            logger.error("Invalid query param", e);
        }
    }

    if (!default_state.start_date && !default_state.end_date) {
        var d = new Date();
        var new_date = d.toISOString().substr(0, 19).replace("T", " ");
        logger.debug("DataGrid default start date", new_date);

        default_state.end_date = new_date;

        var day = d.setTime(d.getTime() - 86400000);
        new_date = d.toISOString().substr(0, 19).replace("T", " ");
        logger.debug("DataGrid default start date", new_date);
        default_state.start_date = new_date;
    }

    const [state, setState] = useState(default_state);
    var app_context = useContext(AppContext);

    const [metaData, setMetaData] = useState(null);
    const [visibleRowCount, setVisibleRowCount] = useState(null);

    const handleFilterChange = (newState) => {
        logger.debug('handleFilterChange', state, newState);
        const updatedState = { ...state, ...newState };
        setState(updatedState);
        handleRefresh();
    };

    const handleRefresh = () => {
        logger.debug('handleRefresh');
        if (gridRef.current) {
            gridRef.current.api.showLoadingOverlay();
            const query = {
                start_date: state.start_date,
                end_date: state.end_date,
                operator_icao_code: state.operator?.operator_icao_code,
                aircraft: state.aircraft?.registration_name,
                'page[number]': state['page[number]'],
                'page[size]': state['page[size]'],
            };
            service
                .fetchRecords(query)
                .then((data) => {
                    setMetaData(service.getMetaData(data));
                    const formattedData = service.formatRowData(data);
                    gridRef.current.api.setRowData(formattedData);
                })
                .catch((err) => {
                    logger.error('Error fetching data', err);
                    gridRef.current.api.setRowData([]);
                    gridRef.current.api.showNoRowsOverlay();
                });
        }
    };

    const handleExport = () => {
        logger.debug('handleExport', state, metaData);
        const filename = `operations_data_${state.start_date}_to_${state.end_date}.csv`;
        gridRef.current.api.exportDataAsCsv({
            fileName: filename,
            skipHeader: false,
            allColumns: true,
        });
    };

    const handleChangePage = (event, newPage) => {
        const updatedState = { ...state, 'page[number]': newPage + 1 };
        setState(updatedState);
        handleRefresh();
    };

    const handleChangeRowsPerPage = (event) => {
        const updatedState = { ...state, 'page[size]': parseInt(event.target.value, 10) };
        setState(updatedState);
        handleRefresh();
    };



    var header = <Typography variant="h5">{props.title}</Typography>;

    return (
        <View title={props.title} fixedContent={true} key={key}>
             <FlightFormFilter
               state={state} 
               onChange={handleFilterChange}
               showRuleNameFilter = {true}
             />
            <AGGrid
                service={service}
                //recordID={props.recordID}
                columnDefs={service.getColumnDefs()}
                filename="field_support"
                header={header}
            />
       </View>
    );
}
