/**
 * @obsolete - test code only
 */ 
import React, { useState, useContext }    from 'react';
import { Logger }               from 'aws-amplify';
import View                     from 'pac-responsive-ui-framework/core/View';
import Typography from '@material-ui/core/Typography';
import AGGrid from '../components/AGGrid'
import AppContext               from 'pac-responsive-ui-framework/core/AppContext';
import UserService              from '../services/UserService';


const logger = new Logger('OpUsers');


export default function OpUsers(props) {
    logger.debug(props);
    const app_context = useContext(AppContext);
    const user_service = new UserService();
    
    var header = <Typography variant="h5">{props.title}</Typography>;
    return (
        <View title={props.title} fixedContent={true}>
                <AGGrid
                service={user_service}
                columnDefs={user_service.getColumnDefs()}
                filename="users"
                header={header}
            />
      </View>
      
    );
    
}